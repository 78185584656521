import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Slider from "react-slick"
import BlogCard from "./BlogCard"

function BlogSlider() {
  const {
    strapiBlogs: { data: strapiBlogData },
  } = useStaticQuery(graphql`
    {
      strapiBlogs {
        data {
          id
          attributes {
            slug
            title
            description
            publishedAt
            images {
              data {
                attributes {
                  localFile {
                    url
                    ext
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            blog_topics {
              data {
                id
                attributes {
                  label
                  slug
                }
              }
            }
          }
        }
      }
    }
  `)
  const getSlideLength = upperBound =>
    strapiBlogData.length > upperBound ? upperBound : strapiBlogData.length

  const sliderSettings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: getSlideLength(3),
    slidesToScroll: getSlideLength(3),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: getSlideLength(3),
          slidesToScroll: getSlideLength(3),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    strapiBlogData.length && (
      <section className="section" id="blogs">
        <div className="container">
          <h3 className="title has-text-centered has-text-primary">Blog</h3>
          <Slider {...sliderSettings}>
            {strapiBlogData.map(({ id, attributes: blog }) => (
              <div className="p-3" key={id}>
                <BlogCard blog={blog} />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    )
  )
}

export default BlogSlider
