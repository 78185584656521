import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import SiteImage from "./SiteImage"

const Technologies = () => {
  const query = graphql`
    query TechnologiesQuery {
      strapiTechnologyCategories {
        data {
          id
          attributes {
            title
            technologies {
              data {
                id
                attributes {
                  logo {
                    data {
                      attributes {
                        ext
                        localFile {
                          url
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                  title
                  url
                }
              }
            }
          }
        }
      }
      strapiTechnologiesSection {
        data {
          attributes {
            title
            description
          }
        }
      }
    }
  `

  const {
    strapiTechnologyCategories: { data: technologyCategories },
    strapiTechnologiesSection: {
      data: {
        attributes: { title: sectionTitle, description: sectionDescription },
      },
    },
  } = useStaticQuery(query)

  return (
    <section id="technologies" className="section has-text-centered">
      <div className="block">
        <div className="title has-text-primary">{sectionTitle}</div>
        <div className="subtitle mb-6 mt-5">{sectionDescription}</div>
      </div>
      {technologyCategories.map(({ attributes: category, id }) => (
        <div key={id}>
          <div className="my-6 title is-4">
            {category.title}
          </div>
          <div className="columns is-multiline is-centered my-6 is-mobile">
            {category.technologies.data.map(
              ({ attributes: technology, id }) => (
                <div className="column technology-item" key={id}>
                  <a href={technology.url} target="_blank" rel="noreferrer">
                    <div className="box">
                      <SiteImage
                        image={technology.logo}
                        alt={technology.title}
                        width="96px"
                        height="96px"
                      />
                      <div>{technology.title}</div>
                    </div>
                  </a>
                </div>
              )
            )}
          </div>
        </div>
      ))}
    </section>
  )
}

export default Technologies
