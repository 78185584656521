import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"

const Carousel = ({ children }) => {
  const settings = {
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "cubic-bezier(0.16, 1, 0.3, 1)",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }

  return (
    <Slider {...settings}>
      {children}
    </Slider>
  )
}
const Testimonials = () => {
  const query = graphql`
    query TestimonialQuery {
      strapiCustomerTestimonial {
        data {
          attributes {
            testimonials {
              comment
              fullName
              id
              title
              photo {
                data {
                  attributes {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    ext
                  }
                }
              }
            }
            description
            title
          }
        }
      }
    }
  `

  const {
    strapiCustomerTestimonial: {
      data: {
        attributes: { testimonials, description, title },
      },
    },
  } = useStaticQuery(query)

  return (
    <section className="section" id="testimonials">
      <div className="container">
        <div className="block has-text-centered mb-6">
          <h3 className="title has-text-primary">{title}</h3>
          <h3 className="subtitle mb-6">{description}</h3>
        </div>

        <Carousel>
          {testimonials.map(testimonial => (
            <div
              key={testimonial.id}
              className="px-3 pt-3 pb-6"
            >
              <div className="box testimonial-card is-flex is-flex-direction-column is-align-items-center">
                <div className="block is-flex is-flex-direction-column is-align-items-center">
                  <div className="image is-128x128 block is-flex is-align-center">
                    {testimonial.photo.data ? (
                      <>
                        {testimonial.photo.data.attributes.ext === ".svg" ? (
                          <img
                            alt={testimonial.fullName}
                            src={
                              testimonial.photo.data.attributes.localFile.url
                            }
                          />
                        ) : (
                          <GatsbyImage
                            alt={testimonial.fullName}
                            imgClassName="is-contained"
                            image={
                              testimonial.photo.data.attributes.localFile
                                .childImageSharp.gatsbyImageData
                            }
                          />
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="content is-flex-grow-1 has-text-centered">
                  <i>{testimonial.comment}</i>
                </div>

                <div className="block has-text-centered">
                  <h6 className="title is-6">{testimonial.fullName}</h6>
                  <h6 className="sub-title is-6">{testimonial.title}</h6>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  )
}

export default Testimonials
