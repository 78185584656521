import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const WhoAreWe = () => {
  const query = graphql`
    query AboutQuery {
      strapiAbout {
        data {
          attributes {
            title
            description
            team {
              id
              title
              description
              image {
                data {
                  attributes {
                    localFile {
                      url
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    ext
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  const {
    strapiAbout: {
      data: {
        attributes: { title, description, team },
      },
    },
  } = useStaticQuery(query)

  return (
    <section id="who-are-we" className="section">
      <div className="container">
        <div className="block has-text-centered">
          <h3 className="title has-text-primary">{title}</h3>
        </div>
        <p>{description}</p>
        {team.map(({ id, title, description, image }) => (
          <div key={id} className="is-flex is-flex-direction-row my-6">
            <div className="mr-6">
              <GatsbyImage
                alt={title}
                image={
                  image.data.attributes.localFile.childImageSharp.gatsbyImageData
                }
                className="image is-96x96"
                imgClassName=" is-rounded"
              />
            </div>
            <div >
              <h4 className="title is-4 has-text-primary">{title}</h4>
              <h4 className="">{description}</h4>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default WhoAreWe
