import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const Solutions = () => {
  const query = graphql`
    query SolutionsQuery {
      strapiSolutions {
        data {
          id
          attributes {
            title
            description
            slug
            pageContent {
              id
            }
            solution_sub_categories {
              data {
                id
                attributes {
                  title
                  slug
                  pageContent {
                    id
                  }
                }
              }
            }
          }
        }
      }
      strapiSolutionsSection {
        data {
          attributes {
            title
            description
          }
        }
      }
    }
  `

  const {
    strapiSolutions: { data: solutionData },
    strapiSolutionsSection: {
      data: {
        attributes: { title: sectionTitle, description: sectionDescription },
      },
    },
  } = useStaticQuery(query)

  return (
    <section id="solutions" className="section has-text-centered">
      <div className="container">
        <div className="block">
          <div className="title has-text-primary">{sectionTitle}</div>
          <div className="subtitle mb-6 mt-5">{sectionDescription}</div>
        </div>
        <div className="columns is-multiline is-centered my-6">
          {solutionData.map(({ attributes: solution, id }) => (
            <div key={`solution-${id}`} className="column is-4 is-flex">
              <div className="box is-flex is-flex-grow-1 is-flex-direction-column">
                <div className="my-4 title is-4">{solution.title}</div>
                <div className="my-4 is-flex-grow-1 has-text-left" key={id}>
                  {solution.description}
                </div>
                {solution.pageContent && (
                  <Link
                    to={`/solutions/${solution.slug}/`}
                    className="is-primary is-align-self-flex-start ml-1"
                  >
                    See More
                  </Link>
                )}
                {solution.solution_sub_categories?.data?.map(
                  ({ attributes: solutionSubCategory, id }) =>
                    solutionSubCategory.pageContent && (
                      <div className="mt-2 has-text-left" key={`solution-sub-${id}`}>
                        <Link
                          to={`/solutions/${solution.slug}/${solutionSubCategory.slug}`}
                          className="is-primary"
                        >
                          {solutionSubCategory.title}
                        </Link>
                      </div>
                    )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Solutions
