import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import SiteImage from "./SiteImage"

const Services = () => {
  const query = graphql`
    query ServiceCategoryQuery {
      strapiServiceCategories {
        data {
          id
          attributes {
            externalLink
            pageContent {
              id
            }
            description
            title
            slug
          }
        }
      }
      strapiServiceSection {
        data {
          attributes {
            title
            description
            specializationsTitle
            serviceListTitle
          }
        }
      }
    }
  `

  const {
    strapiServiceCategories: { data: serviceCategories },
    strapiServiceSection: {
      data: {
        attributes: { title: sectionTitle, description: sectionDescription },
      },
    },
  } = useStaticQuery(query)

  return (
    <section id="services" className="section">
      <div className="container">
        <div className="has-text-centered block">
          <h1 className="title has-text-primary">{sectionTitle}</h1>
          <h2 className="subtitle">{sectionDescription}</h2>
        </div>
        <div className="block my-6">
          <div className="columns is-multiline is-centered">
            {serviceCategories.map(({ attributes: serviceCategory, id }) => (
              <div
                key={id}
                className="column is-half-tablet is-one-third-desktop is-flex"
              >
                <div className="box is-flex is-flex-grow-1 is-flex-direction-column">
                  <div className="block is-flex is-justify-content-center">
                    {serviceCategory.image?.data ? (
                      <figure className="is-flex is-justify-content-center">
                        <SiteImage
                          image={serviceCategory.image}
                          alt={serviceCategory.title}
                          width="96px"
                          height="96px"
                        />
                      </figure>
                    ) : null}
                  </div>
                  <div className="title-container">
                    <div className="title is-4 has-text-centered">
                      {serviceCategory.title}
                    </div>
                    <div className="subtitle is-6 has-text-left my-5">
                      {serviceCategory.description}
                    </div>
                  </div>
                  {serviceCategory.pageContent ? (
                    <Link
                      to={`/services/${serviceCategory.slug}`}
                      className="is-primary is-align-self-flex-start is-primary"
                    >
                      See More
                    </Link>
                  ) : serviceCategory.externalLink ? (
                    <a
                      href={serviceCategory.externalLink}
                      target="_blank"
                      className="is-primary is-align-self-flex-start is-primary"
                    >
                      See More
                    </a>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
