import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SiteImage from "./SiteImage"

const FeaturedProject = () => {
  const query = graphql`
    query featuredProject {
      strapiFeaturedProject {
        data {
          attributes {
            description
            redirectLabel
            redirectTo
            moreLabel
            moreTo
            title
            image {
              data {
                attributes {
                  ext
                  localFile {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `
  const {
    strapiFeaturedProject: {
      data: {
        attributes: {
          description,
          redirectLabel,
          redirectTo,
          moreLabel,
          moreTo,
          title,
          image,
        },
      },
    },
  } = useStaticQuery(query)

  return (
    <section className="section" id="featured-project">
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds">
            <h1 className="title mb-6 has-text-white">{title}</h1>
            <p className="is-size-5 has-text-white">{description}</p>
            <div className="columns mt-3 is-mobile">
              <div className="column is-narrow">
                <a
                  className="button is-size-5 has-text-weight-semibold"
                  href={redirectTo}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {redirectLabel}
                </a>
              </div>
              <div className="column is-narrow">
                <a
                  className="button is-outlined is-size-5 has-text-weight-semibold"
                  href={moreTo}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {moreLabel}
                </a>
              </div>
            </div>
          </div>
          <div className="column is-one-third is-flex is-justify-content-center">
            <SiteImage
              image={image}
              alt="featured-project-background"
              width="270px"
              height="220px"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedProject
