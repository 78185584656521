import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { PlayCircle, MessageCircle, Users, Cast, Code, Cloud } from "react-feather";
import Vivus from "vivus"

const icons = [
  { id: 1, component: PlayCircle },
  { id: 2, component: MessageCircle },
  { id: 3, component: Users },
  { id: 4, component: Cast },
  { id: 5, component: Code },
  { id: 6, component: Cloud },
]

const HeroBackground = () => {
  const [activeIconId, setActiveIconId] = useState(1)

  useLayoutEffect(() => {
    new Vivus(`hero-background-icon-${activeIconId}`, { duration: 50 })
  }, [activeIconId])

  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      setActiveIconId(activeIconId === icons.length ? 1 : activeIconId + 1)
    }, 2500)
    return () => {
      clearTimeout(timeoutHandler)
    }
  }, [activeIconId])

  const IconComponent = icons[activeIconId - 1].component

  return (
    <IconComponent
      id={`hero-background-icon-${activeIconId}`}
      className="hero-background-icon"
      strokeWidth={0.7}
      color={"hsl(40, 90%, 55%)"}
    />
  )
}

const Hero = () => {
  const query = graphql`
    query siteMetaData {
      strapiSiteMetaData {
        data {
          attributes {
            heroTitle
            heroDescription
          }
        }
      }
    }
  `

  const {
    strapiSiteMetaData: {
      data: {
        attributes: { heroTitle, heroDescription },
      },
    },
  } = useStaticQuery(query)

  return (
    <section id="hero" className="section hero is-fullheight is-dark">
      <div className="container is-flex is-flex-direction-row">
        <div className="columns">
          <div
            className="column is-two-thirds is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            style={{ marginBottom: "160px" }}
          >
            <p className="title">{heroTitle}</p>
            <p className="subtitle">{heroDescription}</p>
          </div>
          <div className="column is-one-third is-flex is-justify-content-center is-align-items-center">
            <HeroBackground />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
