import React from "react"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import Technologies from "../components/Technologies"
import Services from "../components/Services"
import Testimonials from "../components/Testimonials"
import WhoAreWe from "../components/WhoAreWe"
import Seo from "../components/SEO"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import "../styles/scss/index.scss"
import FeaturedProject from "../components/FeaturedProject"
import Solutions from "../components/Solutions"
import BlogSlider from "../components/BlogSlider"

export default function Home({ data }) {
  const jsonLD = {
    "@context": "https://schema.org/",
    "@type": "Service",
    serviceType: "Doganbros.com Services",
    provider: {
      "@type": "Business",
      name: "Doganbros.com",
    },
    areaServed: "Worldwide",
    hasOfferCatalog: {
      "@type": "OfferCatalog",
      name: "Doganbros.com Services",
      itemListElement: data.strapiServiceCategories.data.map(service => ({
        "@type": "OfferCatalog",
        itemOffered: {
          "@type": "Service",
          name: service.attributes.title,
        },
      })),
    },
  }

  return (
    <Layout>
      <Seo />
      <Helmet defer={false}>
        <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      <Hero />
      <FeaturedProject />
      <Services />
      <Technologies />
      <Solutions />
      <Testimonials />
      <WhoAreWe />
      <BlogSlider />
    </Layout>
  )
}

export const query = graphql`
  query HomePageServiceQuery {
    strapiServiceCategories {
      data {
        attributes {
          description
          title
        }
        id
      }
    }
  }
`
