import { Link } from "gatsby"
import React from "react"
import SiteImage from "../components/SiteImage"
function BlogCard({ blog }) {
  return (
    <div className="card">
      <Link to={`/blog/${blog.slug}`} className="card-image">
        <figure className="image is-4by3">
          <SiteImage
            alt="blog illustration"
            image={{ data: blog.images.data[0] }}
            className="has-ratio"
          />
        </figure>
      </Link>
      <div className="card-content">
        <div className="level">
          <div className="level-left">
            <div className="tags">
              {blog.blog_topics.data.map(
                ({ attributes: { label, slug }, id }) => (
                  <Link
                    className="tag is-primary is-light"
                    key={id}
                    to={`/blog/categories/${slug}`}
                  >
                    {label}
                  </Link>
                )
              )}
            </div>
          </div>
          <div className="level-right">
            <p className="is-size-7">
              {new Date(blog.publishedAt).toLocaleDateString(undefined, {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </p>
          </div>
        </div>
        <Link to={`/blog/${blog.slug}`}>
          <p className="title is-6 mb-3">{blog.title}</p>
        </Link>
        <div className="has-text-dark">{blog.description}</div>
        <Link to={`/blog/${blog.slug}`}>
          <div className="has-text-primary my-3">Read more</div>
        </Link>
      </div>
    </div>
  )
}

export default BlogCard
